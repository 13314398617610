import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full text-primary-300`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



export default ({
  subheading = "",
  heading = "FAQS",
  description = "Check if your question has already been answered before. If not, drop us a message here.",
  faqs = [
    {
      question: "How does LockMe's Smart Locker System work?",
      answer:
        "Our system provides a hassle-free experience for both end-users and administrators. For users, the process is straightforward: they authenticate themselves using our authentication methods, select an available locker, and they're good to go! For administrators, our cloud-based platform offers full control over the system. From the management portal, they can monitor usage history, configure custom functions, and manage lockers remotely. Looking to explore our customizable features? Contact us for more information!"
    },
    {
      question: "What are the advantages of LockMe's smart lockers?",
      answer:
        "They're numerous and tangible! Our solution provides full traceability of locker usage, optimizing resources and ensuring efficient space management. Additionally, our remote operation drastically reduces labor costs. Dynamic locker rotation allows for flexible storage space allocation, maximizing utilization. With seamless integration into existing systems, we ensure quick and hassle-free implementation, eliminating drawbacks associated with conventional lockers. Want to learn more about how LockMe can transform your workspace? Contact us!"
    },
    {
      question: "Do you implement projects outside of Spain?",
      answer:
        "Yes, LockMe executes projects globally and we are continuously expanding our international presence. Currently, we have smart lockers deployed in various cities across Spain including the Canary Islands, as well as in other countries such as Malta, Armenia, Georgia, Lithuania, and Romania. If you'd like to discuss how we can assist you in your specific location, feel free to reach out to our team. We'll be happy to assist and provide details about our global capabilities."
    },
    {
      question: "How can I integrate LockMe solutions into my company's technological environment?",
      answer:
        "At LockMe, we offer seamless integration with your company's technological environment. With our robust APIs, your IT team can effortlessly incorporate our smart locker solutions into your existing workflow, ensuring efficient communication between your internal systems and our smart locker software."
        +
        " Additionally, we provide an innovative solution that allows companies to modernize their existing lockers. We have the capability to adapt or migrate conventional lockers to smart lockers, maximizing the value of your investment and reducing costs associated with purchasing new lockers. Want to learn more about how we integrate our solutions? Contact us!"       
    },
    {
      question: "Are you manufacturers of smart lockers?",
      answer:
        "Yes, at LockMe, we collaborate with a network of specialized and certified manufacturing partners for our smart lockers. This collaboration allows us to develop custom designs tailored to our key specifications. Our lockers are crafted using high-quality materials, whether phenolic or metal, and are equipped with state-of-the-art electronics and our own control units. This enables us to offer smart lockers designed and tailored to the specific needs of each project, in terms of size, color, and design. If you would like to learn more about our manufacturing capabilities or discuss a specific project, please don't hesitate to Contact us. We'll be happy to assist you."

    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="FAQS">
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useInView } from 'react-intersection-observer';

import LogoImage from "../../images/LOGO_L.png";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";

const Container = tw.div`relative bg-teal-800 text-gray-100 -mx-8 -mb-8 px-8`;
const Content = styled.div(({ inView }) => [
  tw`max-w-screen-xl mx-auto pt-16 pb-8 transition-transform duration-700`,
  inView ? tw`transform translate-x-0` : tw`transform translate-x-80 opacity-0`
]);
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;

const ColumnHeading = tw.h5`font-bold uppercase`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const FooterLink = styled.a`
  ${tw`text-gray-100 hover:text-gray-300 transition duration-300`}

  &:hover {
    color: inherit; /* Inherit the color from the parent, so no blue effect */
    text-decoration: none; /* Optional: Remove underline on hover */
  }
`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-56`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`

export default () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Container ref={ref}>
      <Content inView={inView}>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              <LogoText></LogoText>
            </LogoContainer>
            <CompanyAddress>
              Carrer de Buixeda 119
              08203 Sabadell, Barcelona
            </CompanyAddress>
            <SocialLinksContainer>
              <SocialLink href="https://www.instagram.com/mylock.es/?hl=es" target='_blank'>
                <InstagramIcon />
              </SocialLink>
              <SocialLink href="https://twitter.com/mylock_es" target='_blank'>
                <TwitterIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/company/mylockes/" target='_blank'>
                <LinkedinIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
          </Column>
          <Column>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <FooterLink href="https://storage.googleapis.com/mylock-public-data/terms_and_conditions.pdf" target='_blank'>Privacy politics</FooterLink>
              </LinkListItem>
              <LinkListItem>
                <FooterLink href="https://storage.googleapis.com/mylock-public-data/terms_and_conditions.pdf" target='_blank'>Terms of service</FooterLink>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                +34 633 874 960
              </LinkListItem>
              <LinkListItem>
                <FooterLink href="mailto:info@lock-me.com">info@lock-me.com</FooterLink>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2023, LOCKME ACCESS S.L.</CopyrightNotice>
          <CompanyInfo>CONVERSATIONAL SMART LOCKER SOLUTIONS</CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import LogoImage from "../../images/LOGO_L.png";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-300`}
  }
`;

const LogoImg = styled.a`
  position: absolute;
  bottom: 16px;  /* Adjust this value to position the logo vertically */
  right: 16px;  /* Adjust this value to position the logo horizontally */
  z-index: 30;
  img {
    height: 64px;  /* Adjust the size of the logo */
    width: auto;
  }
`;

const PrimaryLink = styled.a`
  ${tw`rounded-full bg-primary-500 text-gray-100 px-8 py-3 mt-10 text-sm sm:text-base font-bold shadow transition duration-300`}
  &:hover {
    ${tw`bg-primary-700 text-gray-200`}
  }
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 30;
  cursor: pointer;
`;

const Container = styled.div`
  ${tw`max-w-screen-xl w-full h-screen`}  /* Ensure the container occupies full width and height of the screen */
  margin: 0;
  padding: 0;
  overflow: hidden;  /* Hide any overflow content */
`;

const BackgroundVideoWrapper = styled.div`
  ${tw`absolute top-0 left-0 w-full h-auto min-h-full`}  /* Ensure the video wrapper occupies full width and height */
  
`;

const BackgroundVideo = styled.video`
  ${tw`absolute bottom-0 left-0 w-full h-auto min-h-full`}  /* Ensure the video fills the container */
  object-fit: cover;
  object-position: bottom;  /* Align the video to the bottom */
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = styled.a`z-20 absolute justify-right items-right`;
const Content = tw.div`max-w-screen-xl flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-300 leading-snug mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
`;

const DownArrow = styled.div`
  ${tw`absolute bottom-0 left-1/2 transform -translate-x-[-90%] cursor-pointer`}
  font-size: 10rem;
  color: white;
  animation: ${bounce} 2s infinite;
  cursor: pointer;
  z-index: 30;
`;

const scrollToContactSection = () => {
  const nextSection = document.getElementById("contact");
  if (nextSection) {
    nextSection.scrollIntoView({ behavior: "smooth" });
  }
};

export default () => {
  const navLinks = [
    <NavLinks key={2}>
      <PrimaryLink onClick={scrollToContactSection}>
        Contact Us
      </PrimaryLink>
    </NavLinks>
  ];
  
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("Different");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };
 

  return (
    <Container>
      <BackgroundVideoWrapper>
        <BackgroundVideo autoPlay loop muted>
          <source src="oneWhatsappAwayv8.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </BackgroundVideo>
      </BackgroundVideoWrapper>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
            <br />
            <br />
          </Heading>
        </Content>
      </HeroContainer>
      <LogoImg href="https://www.lock-me.com">
        <img src={LogoImage} alt="LockMe Logo" />
      </LogoImg>
      <DownArrow
        style={{
          position: 'absolute',
          bottom: '0', // Adjust the bottom position
          left: '45%',
          transform: 'translateX(-50%)',
          zIndex: 30,
        }}
        onClick={scrollToNextSection}
      >
        <svg width="198" height="198" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 9L12 15L18 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </DownArrow>
    </Container>
  );
};
